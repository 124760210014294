import firebase from "gatsby-plugin-firebase";
import { getUser, setUserMData } from "./auth";

export const generateMeetingId = async (
    meetingType = 'instant', 
    meetingTitle = '', 
    meetingPassword = '', 
    hostVideo = true, 
    hostAudio = true,
    participantVideo = true,
    participantAudio = true,
    startTime = '', 
    endTime = '',
    timeZoneForInvite = '') => {
    try {
        const { uid } = getUser();
        const hostUUID = uid || '';
        let meetingInfo = {
            meetingTitle,
            meetingPassword,
            hostUUID,
            hostPreferences: {
                audio: hostAudio,
    		    video: hostVideo
            },
            participantPreferences: {
                audio: participantAudio,
                video: participantVideo
            }
        };

        if (meetingType == 'scheduled') {
            meetingInfo.timeZoneForInvite = timeZoneForInvite;
        }

        const data = {
            startTime,
            endTime,
            meetingType,
            meetingInfo
        };

        const apiCall = firebase.functions().httpsCallable("generateMeetingId");
        const response = await apiCall(data);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getUserMetaData = async (uid) => {
    try {
        const db = firebase.firestore();

        const docRef = db.collection('userMetaData').doc(uid);
        const doc = await docRef.get();

        if (doc.exists) {
            setUserMData(doc.data());
            return doc.data();
        } else {
            const data = {
                country: "Not Set",
                jobTitle: "Not Set",
                meetingPreferences: {
                    audioOn: true,
                    videoOn: true
                },
                profileName: "Guest",
                profileUrl: "",
                timezoneId: "Asia/Calcutta",
                userType: "basic"
            };
            await setUserMetaData(uid, data);
            setUserMData(data);
            return data; 
        }
    } catch (error) {
        console.log(error);
        return {};
    }
}

export const setUserMetaData = async (uid, data) => {
    try {
        const db = firebase.firestore();
        const docRef = db.collection('userMetaData').doc(uid);
        await docRef.set(data);
        setUserMData(data);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const updatePassword = async (newPassword) => {
    try {
        const user = firebase.auth().currentUser;
        await user.updatePassword(newPassword);
        console.log('Password Updated!');
    } catch (error) {
        console.log(error);
    }
}

export const getScheduledMeetings = async (uid) => {
    try {
        const db = firebase.firestore();
        const docRef = db.collection('userData').doc(uid);
        const doc = await docRef.get();

        if (doc.exists) {
            const data = doc.data();
            return data['upcoming_meetings'];
        } else {
            return [];
        }
    } catch (error) {
        console.log(error);
        return [];
    }
}

export const updateScheduleMeetings = async (uid, data) => {
    try {
        const db = firebase.firestore();
        const docRef = db.collection('userData').doc(uid);
        docRef.set({
            upcoming_meetings: data
        });

        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}